<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div>
                    <md-button @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                    <md-button @click="close()" class="md-raised">Đóng</md-button>
                </div>
            </div>
            <md-content>
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="group-box" style="margin-top:10px;">
                                <div class="group-title">TIỀN CƯỢC SỬA CHỮA</div>
                                <div class="box-content" style="padding: 10px 10px 0 10px;">
                                    <div class="row">
                                        <div class="col l-6 m-6 c-12">
                                            <div class="row">
                                                <div class="col l-6 m-6 c-12">
                                                    <md-field>
                                                        <label for="serial">Số cont</label>
                                                        <md-input disabled name="serial" v-model="entity.orderDetail.serial"></md-input>
                                                    </md-field>
                                                </div>
                                                <div class="col l-6 m-6 c-12">
                                                    <md-field>
                                                        <label for="serial">Loại cont</label>
                                                        <md-input disabled name="serial" v-model="entity.orderDetail.unit.unitName"></md-input>
                                                    </md-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-6 m-6 c-12">
                                                    <md-field>
                                                        <label for="price">Đơn giá</label>
                                                        <number disabled v-model="entity.invoicePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                    </md-field>
                                                </div>
                                                <div class="col l-6 m-6 c-12">
                                                    <md-field>
                                                        <label for="price">Vat</label>
                                                        <number disabled v-model="entity.invoiceVat" v-bind="vatF" class="form-control currency"></number> 
                                                    </md-field>
                                                </div>
                                            </div>
                                            <md-field>
                                                <label for="price">Thành tiền</label>
                                                <number disabled v-model="entity.invoiceAmount" v-bind="currencyF" class="form-control currency"></number>
                                            </md-field>
                                        </div>
                                        <div class="col l-6 m-6 c-12">
                                            <md-field>
                                                <label for="orderCode">Mã đơn hàng</label>
                                                <md-input disabled name="orderCode" v-model="entity.order.orderCode" ></md-input>
                                            </md-field>
                                            <div class="row">
                                                <div class="col l-6 m-6 c-12">
                                                    <md-field>
                                                        <label for="invoiceNumber">Số phiếu thu</label>
                                                        <md-input disabled name="invoiceNumber" v-model="entity.invoiceNumber"></md-input>
                                                    </md-field>
                                                </div>
                                                <div class="col l-6 m-6 c-12">
                                                    <md-field>
                                                        <label for="invoiceDate">Ngày phiếu thu</label>
                                                        <md-input disabled name="invoiceDate" v-model="entity.invoiceDate"></md-input>
                                                    </md-field>
                                                </div>
                                            </div>
                                            <md-field>
                                                <label for="invoiceNote">Lý do</label>
                                                <md-input disabled name="invoiceNote" v-model="entity.invoiceNote"></md-input>
                                            </md-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col l-6 m-6 c-12">
                                            <md-checkbox v-model="entity.backDepositStatus" :value="1">Đã thu hồi tiền cược</md-checkbox>
                                        </div>
                                        <div class="col l-6 m-6 c-12">
                                            <md-datepicker v-model="entity.backDepositDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && entity.backDepositStatus == 1 && (entity.backDepositDate == null || entity.backDepositDate == '') }">
                                                <label>Ngày thu hồi</label>
                                                <span class="md-error" v-if="submitted && entity.backDepositStatus == 1 && (entity.backDepositDate == null || entity.backDepositDate == '')">Vui lòng chọn ngày thu hồi</span>
                                            </md-datepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="group-box" style="margin-top:20px;">
                                <div class="group-title">THÔNG TIN CẬP NHẬT</div>
                                <div class="box-content" style="padding: 10px 10px 0 10px;">
                                    <div class="row">
                                        <div class="col l-6 m-6 c-12"><md-checkbox v-model="checkRepair" :value="true">Phát sinh chi phí sửa chữa</md-checkbox></div>
                                    </div>
                                    <div class="row">
                                        <div class="col l-6 m-6 c-12">
                                            <div class="row">
                                                <div class="col l-6 m-6 c-12">
                                                    <md-field :class="{'md-invalid': submitted && checkRepair && entity.depositRepairPrice <= 0 }">
                                                        <label for="price">Đơn giá<span class="label-require">(*)</span></label>
                                                        <number :disabled="!checkRepair" @change="calSum()" v-model="entity.depositRepairPrice" v-bind="currencyF" class="form-control currency"></number>
                                                        <span class="md-error" v-if="submitted && checkRepair && entity.depositRepairPrice <= 0 ">Vui lòng nhập đơn giá quyết toán</span>
                                                    </md-field>
                                                </div>
                                                <div class="col l-6 m-6 c-12">
                                                    <md-field>
                                                        <label for="price">Vat</label>
                                                        <number :disabled="!checkRepair" @change="calSum()" v-model="entity.depositRepairVat" v-bind="currencyF" class="form-control currency"></number>
                                                    </md-field>
                                                </div>
                                            </div>
                                            <md-field>
                                                <label for="price">Thành tiền sửa chữa</label>
                                                <number disabled v-model="entity.depositRepairAmount" v-bind="currencyF" class="form-control currency"></number>
                                            </md-field>
                                        </div>
                                        <div class="col l-6 m-6 c-12">
                                            <div class="radio-group">
                                                <label>Loại sửa chữa</label>
                                                <div class="form-control">
                                                    <md-radio v-model="entity.depositRepairStatus" :value="1">Chi hộ</md-radio>
                                                    <md-radio v-model="entity.depositRepairStatus" :value="2">Lái xe</md-radio>
                                                    <md-radio v-model="entity.depositRepairStatus" :value="3">Chi phí Công ty</md-radio>
                                                </div>
                                            </div>
                                            <md-field>
                                                <label for="depositRepairNote">Ghi chú</label>
                                                <md-input :disabled="!checkRepair" name="depositRepairNote" v-model="entity.depositRepairNote"></md-input>
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
    import jobEstimateService from '../../../api/jobEstimateService';
    import refundService from '../../../api/refundService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';

    export default {
        data() {
            return {
                entity: { id: 0, estimateId: 0, orderId: 0, order: { orderCode: '' }, orderDetailId: 0, orderDetail: { serial: '', unit: { unitName: '' } } },
                submitted: false,
                showDialog: false,
                vatF: common.vatF,
                currencyF: common.currencyF2,
                title: "Cập nhật tình trạng cược sửa chữa cont",
                checkRepair: false,
                obj: {}
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            open(obj){
                this.submitted = false;
                this.obj = obj;
                this.checkRepair = null;
                this.entity = {
                    id: obj.id,
                    jobId: obj.job !== null ? obj.job.id : 0,
                    jobEstimateDetailId: obj.id,
                    estimateId: obj.estimateId,
                    orderId: obj.orderId,
                    order: { orderCode: obj.order.orderCode },
                    orderDetailId: obj.orderDetailId,
                    orderDetail: obj.orderDetail,
                    feeId: obj.feeId,
                    type: obj.type,
                    invoicePrice: obj.explainPrice,
                    invoiceVat: obj.explainVat,
                    invoiceAmount: obj.explainAmount,
                    invoiceDate: obj.invoiceDate,
                    invoiceNumber: obj.invoiceNumber,
                    invoiceNote: obj.invoiceNote,
                    depositRepairPrice: obj.depositRepairPrice > 0 ? obj.depositRepairPrice : 0,
                    depositRepairVat: obj.depositRepairVat > 0 ? obj.depositRepairVat : 0,
                    depositRepairAmount: obj.depositRepairAmount > 0 ? obj.depositRepairAmount : 0,
                    depositRepairStatus: obj.depositRepairStatus > 0 ?  obj.depositRepairStatus : 0,
                    backDepositDate: obj.backDepositDate,
                    backDepositStatus: obj.backDepositStatus > 0 ?  obj.backDepositStatus : 0,
                    depositRepairNote: obj.depositRepairNote
                };
                if(this.entity.depositRepairStatus > 0){
                    this.checkRepair = true;
                }
                this.showDialog = true;
            },

            calSum(){
                this.entity.depositRepairAmount = parseFloat(this.entity.depositRepairPrice) * (parseFloat(this.entity.depositRepairVat/100) + 1);
            },

            close(){
                this.showDialog = false;
            },

            submit(){
                this.submitted = true;
                if (this.entity.backDepositStatus == 1 && (this.entity.backDepositDate == '' || this.entity.backDepositDate == null)) {
                    return;
                }
                if (this.checkRepair == true && this.entity.depositRepairStatus == 1 && this.entity.depositRepairPrice <= 0) {
                    return;
                }
                if(this.entity.jobId > 0){
                    this.updateJobEstimate();
                }
                else{
                    this.updateRefund();
                }

                this.showDialog = false;
            },

            updateRefund(){
                this.setLoading(true);
                refundService.updateDepositRepair(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('refresh');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            updateJobEstimate(){
                this.setLoading(true);
                jobEstimateService.updateDepositRepair(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('refresh');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
        watch: { 
            checkRepair: function (val){
                if(val){
                    this.entity.depositRepairStatus = this.obj.depositRepairStatus > 0 ?  this.obj.depositRepairStatus : 1,
                    this.entity.depositRepairPrice = this.obj.depositRepairPrice > 0 ? this.obj.depositRepairPrice : 0;
                    this.entity.depositRepairVat = this.obj.depositRepairVat > 0 ? this.obj.depositRepairVat : 0;
                    this.entity.depositRepairAmount = this.obj.depositRepairAmount > 0 ? this.obj.depositRepairAmount : 0;
                }
                else{
                    this.entity.depositRepairStatus = 0;
                    this.entity.depositRepairPrice = 0;
                    this.entity.depositRepairVat = 0;
                    this.entity.depositRepairAmount = 0;
                }
            }
        }
    }
</script>

<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 900px;
        height: 650px;
        max-width: 900px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>