import httpClient  from '.'; 
const API_CONTROLLER = 'refund/'; 

export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    deleteAllSubVendor(orderDetailIds){ 
        let url = API_CONTROLLER + 'delete-all-sub-vendor';
        return httpClient.delete(url, {
            params: {
                orderDetailIds: orderDetailIds
            }
        });
    },

    deleteBySubVendorId(orderDetailId, vendorId){ 
        let url = API_CONTROLLER + 'delete-by-sub-vendor-id';
        return httpClient.delete(url, {
            params: {
                orderDetailId: orderDetailId,
                vendorId: vendorId
            }
        });
    },

    deleteByVendorId(orderDetailId, vendorId){ 
        let url = API_CONTROLLER + 'delete-by-vendor-id';
        return httpClient.delete(url, {
            params: {
                orderDetailId: orderDetailId,
                vendorId: vendorId
            }
        });
    },

    updateDetail(data) { 
        let url = API_CONTROLLER + 'update-detail';
        return httpClient.post(url, data);
    },

    updateDetailSubVendor(vendorId, orderDetailId, data) { 
        let url = API_CONTROLLER + 'update-detail-sub-vendor?vendorId=' + vendorId + "&orderDetailId=" + orderDetailId;
        return httpClient.post(url, data);
    },

    updateDetailAllSubVendor(vendorId, orderId, detailIds, data) { 
        let url = API_CONTROLLER + 'update-detail-all-sub-vendor?vendorId=' + vendorId + "&orderId=" + orderId + "&detailIds=" + detailIds;
        return httpClient.post(url, data);
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getByJobId(jobId) { 
        let url = API_CONTROLLER + 'get-by-job-id';
        return httpClient.get(url, {
            params: {
                jobId: jobId
            }
        });
    },
    
    getByOrderId(orderId) { 
        let url = API_CONTROLLER + 'get-by-order-id';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getAll(orderId) { 
        let url = API_CONTROLLER + 'get-all';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                vendorId: search.vendorId,
                orderId: search.orderId,
                noBooking: search.noBooking,
                orderCode: search.orderCode,
                code: search.code,
                fromDateOrder: search.fromDateOrder,
                toDateOrder: search.toDateOrder,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    getRevenues(search) { 
        let url = API_CONTROLLER + 'get-revenues';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                code: search.code
            }
        });
    },

    getFeeOnbehalf(orderId, companyId) { 
        let url = API_CONTROLLER + 'get-fee-onbehalf';
        return httpClient.get(url, {
            params: {
                orderId: orderId,
                companyId: companyId
            }
        });
    },

    getFeeDistribute(orderId, distributeId) { 
        let url = API_CONTROLLER + 'get-fee-distribute';
        return httpClient.get(url, {
            params: {
                orderId: orderId,
                distributeId: distributeId
            }
        });
    },

    getForDebt(customerId) { 
        let url = API_CONTROLLER + 'get-for-debt';
        return httpClient.get(url, {
            params: {
                customerId: customerId
            }
        });
    },

    getCode(staffId) { 
        let url = API_CONTROLLER + 'get-code?staffId=' + staffId;
        return httpClient.get(url);
    },

    getDetails(orderDetailId){
        let url = API_CONTROLLER + 'get-details?orderDetailId=' + orderDetailId;
        return httpClient.get(url);
    },

    getDetailsByJobId(jobId, orderId, orderDetailId){
        let url = API_CONTROLLER + 'get-details-by-job-id';
        return httpClient.get(url, {
            params: {
                jobId: jobId,
                orderId: orderId,
                orderDetailId: orderDetailId
            }
        });
    },

    getDetailVendor(orderDetailId, vendorId){
        let url = API_CONTROLLER + 'get-detail-by-vendor';
        return httpClient.get(url, {
            params: {
                orderDetailId: orderDetailId,
                vendorId: vendorId
            }
        });
    },

    getDetailSubVendor(orderDetailId, subVendorId){
        let url = API_CONTROLLER + 'get-detail-by-sub-vendor';
        return httpClient.get(url, {
            params: {
                orderDetailId: orderDetailId,
                subVendorId: subVendorId
            }
        });
    },

    getAllDetailByVendor(orderId, vendorId){
        let url = API_CONTROLLER + 'get-all-detail-by-vendor';
        return httpClient.get(url, {
            params: {
                orderId: orderId,
                vendorId: vendorId
            }
        });
    },

    updateDepositRepair(data) { 
        let url = API_CONTROLLER + 'update-deposit-repair';
        return httpClient.post(url, data);
    },
}